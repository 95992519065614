import SearchInput from '@/components/searchInput';
import { getClassNames } from '@/utils/helpers';
import { Listbox } from '@headlessui/react';
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';
import { ReactComponent as Tick } from '@icons/circle-tick-lg.svg';
import { useEffect, useState } from 'react';

type MerchantOverviewProps = {
  project: DropDownProject | undefined;
  projectList: DropDownProject[];
  handleProjectSelect: (value: DropDownProject) => void;
};

type DropDownProject = {
  label: string;
  value?: string;
};

const DashboardOverview = ({
  project,
  projectList,
  handleProjectSelect,
}: MerchantOverviewProps) => {
  const [searchedText, setSearchedText] = useState('');
  const [searchedPartnersList, setSearchedPartnersList] =
    useState<DropDownProject[]>(projectList);

  useEffect(() => {
    let filterProjects = [];
    if (searchedText) {
      const regex = new RegExp(searchedText, 'i');
      filterProjects = projectList?.filter((partner) => regex.test(partner?.label));
    } else {
      filterProjects = projectList;
    }
    setSearchedPartnersList(filterProjects);
  }, [searchedText]);

  if (projectList?.length > 0) {
    return (
      <Listbox value={project}>
        <div className="relative">
          <Listbox.Button
            className={`w-full relative flex items-center gap-2 text-blacked font-medium rounded-sm text-[17px] leading-lg -tracking-0.2 pr-2 py-1  justify-between`}
          >
            {project?.label}
            <CaretIcon />
          </Listbox.Button>
          <Listbox.Options
            className={`w-64 z-20 bg-white rounded-lg shadow-1 px-1 border border-solid border-grey-stroke-primary mt-2 pb-1 absolute top-full left-0 max-h-[500px] overflow-auto`}
          >
            <div className="p-2 mb-2 border-stroke-secondary">
              <span className="text-grey-content-tertiary font-medium text-base">
                Project
              </span>
            </div>
            <SearchInput
              queryParam={searchedText}
              handleChange={(query) => {
                setSearchedText(query);
              }}
              className="rounded p-2 mb-3 w-36"
              setSearchOnClick={false}
            />
            {searchedPartnersList.map((projectData: DropDownProject) => (
              <div className="relative group cursor-pointer" key={projectData.value}>
                <Listbox.Option
                  className={({ active, selected }) => {
                    return `block my-1 px-2 font-medium text-base leading-5 -tracking-0.2 rounded-md 
                      ${project?.label === projectData.label ? 'bg-stroke-primary !text-black' : ''} ${active || selected ? '  bg-stroke-primary' : 'text-grey-content-secondary'}`;
                  }}
                  value={{
                    label: projectData.label,
                    value: projectData.value,
                  }}
                  key={projectData.value}
                  onClick={() => {
                    handleProjectSelect({
                      label: projectData.label,
                      value: projectData.value,
                    });
                  }}
                >
                  <div>
                    <span
                      className={getClassNames(
                        'paragraph1 flex py-2 pr-2 w-full justify-between'
                      )}
                    >
                      {projectData.label}
                      {project?.label === projectData.label ? <Tick width={12} /> : <></>}
                    </span>
                  </div>
                </Listbox.Option>
              </div>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    );
  }
  return <h4 className="header4 text-lg">Home</h4>;
};

export default DashboardOverview;
