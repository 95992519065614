import { Fragment, useContext, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';

// Utils
import { getClassNames } from '@utils/helpers';
import { axiosInstance } from '@utils/baseAxios';
import { FraudRiskTypeProps } from '@redux/features/projects';
import { DemoGuidePopoverContext } from '@context/demoGuidePopover';

// Components
import DemoGuidePopover from '@components/DemoGuidePopover';
import { InputContainer } from '@components/input';
import RadioButton from '@components/RadioButton';
import ComingSoonToolTip from '@/components/ComingSoon/comingSoonToolTip';
import { ReactComponent as FraudRiskTypeIcon } from '@icons/FraudRiskType.svg';

// Assets
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';

type FraudRiskSelectProps = {
  fraudRiskValue?: {
    id: string;
    title: string;
    description: string;
  };
  setFraudRiskValue: (value: any) => void;
  setStage: (value: any) => void;
  step: number;
  stage: number;
};

const FraudRiskSelect = ({
  fraudRiskValue,
  setFraudRiskValue,
  setStage,
  step,
  stage,
}: FraudRiskSelectProps) => {
  const [fraudRiskTypeList, setFraudRiskTypeList] = useState<FraudRiskTypeProps[]>([]);

  const { setPopUp10, setPopUp23, popUp23 } = useContext(DemoGuidePopoverContext);
  const [showFraudRiskTypeTooltip, setFraudRiskTypeInfoTooltip] = useState(false);

  useEffect(() => {
    fetchFraudRiskTypeList();
  }, []);

  useEffect(() => {
    if (step === 0 && stage === 0) setOpen(true);
    else setOpen(false);
  }, [stage, step]);

  const fetchFraudRiskTypeList = async () => {
    try {
      const res = await axiosInstance.get('/fraud_risk_types');
      if (res.status === 200) {
        if (res.data?.length) {
          setFraudRiskTypeList(res.data);
        }
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  const [open, setOpen] = useState(false);

  return (
    <div id="fraudRiskSelect">
      <DemoGuidePopover
        title="Fraud risk type"
        body=""
        el={() => document.getElementById('fraudRiskSelect')}
        topOffset={80}
        leftOffset={-20}
        arrowPosition="top"
        maxWidth="max-w-[355px]"
        open={popUp23}
        setOpen={setPopUp23}
        setNext={setPopUp10}
      >
        <p className="text-grey-content-tertiary mt-3">
          A fraud risk type is a customised Consumer Trust Index preset that solves a
          particular type of fraud.
          <br />
          <p className="text-grey-content-tertiary mt-3">
            We’ve preselected the relevant fraud type for this demo use case.
          </p>
        </p>
      </DemoGuidePopover>
      <InputContainer
        className="relative z-10 max-h-[52px] cursor-pointer mt-2 mb-8"
        label="Fraud risk type"
        labelStyle="text-body-black text-lg"
        onClick={() => setOpen((prev) => !prev)}
        setFraudRiskTypeInfoTooltip={setFraudRiskTypeInfoTooltip}
        showFraudRiskTypeTooltip={showFraudRiskTypeTooltip}
      >
        <div>
          {showFraudRiskTypeTooltip && (
            <ComingSoonToolTip
              className="left-0 top-0"
              isShow={showFraudRiskTypeTooltip}
              icon={<FraudRiskTypeIcon />}
              title="Fraud risk type"
              content={[
                'Consumer Trust Index is tailored for different patterns of first-party fraud. Fraud risk assessments for each pattern of first-party fraud are unique. Select the relevant type of fraud. If uncertain which fraud risk type to choose, select the balanced ‘Friendly fraud’ risk type or speak with our support team for consultation.',
              ]}
            />
          )}
          <div
            className={getClassNames(
              'flex items-center gap-x-2 justify-between w-full h-full',
              'bg-white4 border-0  text-left'
            )}
          >
            <span className="text-body-black text-base leading-5 font-medium capitalize">
              {fraudRiskValue?.title}
            </span>

            <span className="pointer-events-none mt-1">
              <CaretIcon />
            </span>
          </div>
          <Transition appear show={open} as="div" className="-ml-4 mt-3">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={getClassNames(
                  'absolute bg-white p-2 pt-0.5 max-w-[676px] rounded  border border-stroke shadow-1'
                )}
              >
                {fraudRiskTypeList?.map((option) => (
                  <div key={option.title}>
                    <div
                      onClick={() => {
                        setFraudRiskValue(option);
                        setStage(1);
                      }}
                      className={getClassNames(
                        'grid grid-cols-[1fr,20px] items-center gap-x-4 w-full p-4 hover:bg-grey rounded mt-0.5',
                        fraudRiskValue?.title === option.title ? 'bg-grey ' : 'opacity-70'
                      )}
                    >
                      <div className={getClassNames('flex flex-col items-center ')}>
                        <span
                          className={getClassNames(
                            'truncate w-full text-lg leading-5 font-medium text-body-black capitalize flex justify-between'
                          )}
                        >
                          <span className="mr-3 truncate">{option.title}</span>
                          {fraudRiskValue?.title !== option.title && (
                            <span className="text-base px-[8px] py-[1px] text-white bg-grey-content-tertiary rounded">
                              Coming soon
                            </span>
                          )}
                        </span>
                        <span
                          className={getClassNames(
                            'w-full font-medium text-[1.09375rem] leading-base1 text-desc-grey mt-2 max-w-[580px]',
                            fraudRiskValue?.title === option.title
                              ? 'text-grey-content-secondary'
                              : ' text-grey-content-tertiary'
                          )}
                        >
                          {option.description}
                        </span>
                      </div>
                      {fraudRiskValue?.title === option.title && (
                        <div className=" ml-auto">
                          <RadioButton active={fraudRiskValue?.title === option.title} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Transition.Child>
          </Transition>
        </div>
      </InputContainer>
    </div>
  );
};

export default FraudRiskSelect;
