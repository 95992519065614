import { configureStore } from "@reduxjs/toolkit";

import projectSlice from "@redux/features/projects";


const store  = configureStore({
  reducer: {
    projectSlice,
  },
});

export default store;

export type IRootState = ReturnType<typeof store.getState>