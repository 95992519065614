import React, { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';

// Utils
import { getClassNames } from '@utils/helpers';

// Redux
import { Project, getProjects, setSelectedProject } from '@redux/features/projects';
// import { IRootState } from "@/redux/store";

// Context
import { DemoGuidePopoverContext } from '@/context/demoGuidePopover';

// Hooks
import useScreenSize from '@hooks/useScreenSize';

// Components
import { Button } from '@components/button';
import CaretSm from '@icons/CaretSm';
import DemoGuidePopover from '@/components/DemoGuidePopover';
import DropDown from '@components/dropdown';
// import ProjectStateToggle from "@components/ProjectStateToggle";
import ShoppersCharts from './components/shoppersCharts';
import ShopperConfig from './components/shopperConfig';
import ShoppersList from './components/shoppersList';
import ShoppersListReturnsCopilot from './components/shoppersListReturnsCopilot';
import Topbar from '@components/topbar';
import DashboardOverview from '@/components/dashboardOverview';
import ProjectStateToggle from '@/components/ProjectStateToggle';
import MerchantAccuracy from '@/components/MerchantAccuracy';
import MerchantPerformance from '@/components/MerchantPerformance';
import MerchantInfraStructure from '@/components/MerchantInfraStructure';
import MerchantNetwork from '@/components/MerchantNetwork';

type DropDownProject = Project & {
  label: string;
  value?: string;
};
const Dashboard = () => {
  const { ref, inView } = useInView({
    threshold: 1,
  });
  const { height } = useScreenSize();
  const [collapse, setCollapse] = useState(false);
  const [selectedShopper, setSelectedShopper] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const { projects } = useSelector((state: IRootState) => state.projectSlice);
  const [project, setProject] = useState<DropDownProject>();
  const tabOptions = [
    'Operations',
    'Network',
    'Accuracy',
    'Performance',
    'Infrastructure',
  ];
  const ComingSoonList = {
    PerformanceDashboard: true,
    AccuracyDashboard: true,
    InfrastructureDashboard: true,
    ConsumerTrustIndex: true,
    TrustedConsumerScore: true,
    Webhook: true,
  };
  // const [environment, setEnvironment] = useState("SANDBOX");

  const dispatch = useDispatch();

  const useCase = localStorage.getItem('product') ?? 'PAYMENT_PERSONALIZATION';
  useEffect(() => {
    dispatch(getProjects() as any).then(({ payload }: { payload: Project[] }) => {
      if (payload?.length > 0) {
        payload?.map((project: Project) => {
          if (project.products === useCase) {
            !localStorage.getItem('product') &&
              localStorage.setItem('product', project.products);
            setProject({ label: project.name, ...project });
            dispatch(setSelectedProject(project));
          }
          return 0;
        });
      }
    });
  }, [dispatch, useCase]);

  const handleProjectSelect = (prj: { label: string; value?: string }) => {
    return;
  };

  const { popUp2, setPopUp2, setPopUp1, setPopUp3, showExploreDemo } = React.useContext(
    DemoGuidePopoverContext
  );

  return (
    <div
      className={getClassNames(
        'flex w-full flex-col h-screen bg-white overflow-hidden',
        showExploreDemo ? 'blur-sm' : ''
      )}
    >
      <Topbar title="">
        <div className="flex items-center text-nowrap  w-[300px]">
          {selectedIndex == 0 ? (
            <DashboardOverview
              project={{ label: project?.label ?? '', value: project?.value }}
              projectList={[{ label: project?.label ?? '', value: project?.value }]}
              handleProjectSelect={handleProjectSelect}
            />
          ) : (
            <h4 className="header4 text-lg">Insights</h4>
          )}
        </div>
        <div className="relative w-full">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className=" flex gap-2 justify-center">
              {tabOptions?.map((tabTitle) => (
                <Tab key={tabTitle}>
                  {({ selected }) => (
                    <div
                      className={getClassNames(
                        'px-4 py-2 border border-grey-stroke-primary rounded font-medium',
                        selected
                          ? 'text-trud-black bg-grey-stroke-primary'
                          : 'text-grey-content-secondary'
                      )}
                    >
                      {tabTitle}
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>
        {/* <div className="flex justify-end items-center mr-4 w-[250px] max-w-[250px]">
          <ProjectStateToggle
            className="rounded"
            value={environment}
            onChange={setEnvironment}
          />
        </div> */}
        <div className="flex justify-end items-center mr-4 w-[250px] max-w-[250px]">
          <ProjectStateToggle
            className="rounded"
            value={'SANDBOX'}
            onChange={() => {
              return;
            }}
          />
        </div>
      </Topbar>
      <div className="relative flex-1 flex flex-col h-full overflow-auto px-8 py-7">
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="hidden">
            <Tab></Tab>
            <Tab></Tab>
            <Tab></Tab>
            <Tab></Tab>
            <Tab></Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="flex items-center justify-between">
                <h6 className="header6 text-body-black">Overview</h6>
                <Button
                  title="Sep '24"
                  className="pt-[2px] -mt-1 pb-[2px] px-[6px] text-sm1 text-grey-content-tertiary"
                  suffixIcon={<CaretSm color="#747678" className="ml-1 rotate-180" />}
                />
              </div>
              <ShoppersCharts iref={ref as unknown as React.RefObject<HTMLDivElement>} />
              <div
                className={getClassNames(
                  !inView ? 'sticky -top-10 max-h-full' : '',
                  'relative'
                )}
                id="target2"
              >
                <DemoGuidePopover
                  title="1. Network analytics"
                  body="Overview of consumers in your network indicating your organisation’s  fraud profile."
                  el={() => document.getElementById('target2')}
                  topOffset={20}
                  leftOffset={0}
                  arrowPosition="top"
                  open={popUp2}
                  setOpen={setPopUp2}
                  setPrev={setPopUp1}
                  setNext={setPopUp3}
                />
                <h6 className="header6 text-body-black mt-6 relative">
                  {useCase === 'CHECKOUT_AND_REFUND_MANAGER'
                    ? 'Refunds Orchestrator'
                    : 'Trust Network'}
                </h6>
                <div
                  className={getClassNames(
                    'relative flex flex-1 border rounded-lg border-grey-stroke-primary bg-white mt-2'
                  )}
                  style={{ height: `${height - 96}px` }}
                >
                  {<ShopperConfig {...{ collapse, setCollapse }} />}
                  {useCase === 'CHECKOUT_AND_REFUND_MANAGER' ? (
                    <ShoppersListReturnsCopilot
                      {...{
                        project,
                        collapse,
                        setCollapse,
                        selectedShopper,
                        setSelectedShopper,
                      }}
                    />
                  ) : (
                    <ShoppersList
                      {...{
                        project,
                        collapse,
                        setCollapse,
                        selectedShopper,
                        setSelectedShopper,
                      }}
                    />
                  )}
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <MerchantNetwork />
            </Tab.Panel>
            <Tab.Panel>
              <MerchantAccuracy />
            </Tab.Panel>
            <Tab.Panel>
              <MerchantPerformance />
            </Tab.Panel>
            <Tab.Panel>
              <MerchantInfraStructure />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Dashboard;
