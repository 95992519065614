import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@/redux/store';

// Redux
import { setTrustedShopperTotal } from '@redux/features/projects';

// Context
import { DemoGuidePopoverContext } from '@/context/demoGuidePopover';

// Utils
import { getClassNames } from '@utils/helpers';
import { config_type } from '@/utils/constants';

// Components
import DemoGuidePopover from '@components/DemoGuidePopover';
import Input from '@components/input';
import PublishModal from './publishModal';
import PublishSuccess from './publishSuccess';
import { ReactComponent as InfoIcon } from '@assets/icons/info_icon.svg';
import ComingSoonToolTip from '@/components/ComingSoon/comingSoonToolTip';
import ConsumerTrustIndexImage from '@images/Coming Soon/Consumer Trust Index.png';
import RecommendedWeightingsImage from '@images/Coming Soon/Recommended weightings.png';

type TrustedShopperConfigProps = {
  id: string;
  name: string;
  score: string;
  recommended: string;
  description: string;
  project_id: string;
  config_type: config_type;
  environment: string;
  createdAt: string;
  updatedAt: string;
};

type TrustedShopperConfigSetupProps = {
  step: number;
  stepsData: any;
  stage: number;
  register: any;
};

const TrustedShopperConfigSetup: React.FC<TrustedShopperConfigSetupProps> = ({
  step,
  stepsData,
  stage,
  register,
}) => {
  const dispatch = useDispatch();
  const { projectType, trustedShopperConfig } = useSelector(
    (state: IRootState) => state.projectSlice
  );
  const { popUp18, setPopUp18, setPopUp19 } = useContext(DemoGuidePopoverContext);

  const trustIndexAtrr = trustedShopperConfig || [];
  const [showCustomisationTooltip, setCustomisationInfoTooltip] = useState(false);
  const [showRecommendedTooltip, setShowRecommendedTooltip] = useState(false);

  const [editableScoresStage2, setEditableScoresStage2] = useState<
    Record<string, { score: number; config_type: config_type }>
  >(() => {
    const edt: Record<string, { score: number; config_type: config_type }> = {};
    trustIndexAtrr?.forEach(
      (item: { id: string; score: string; config_type: config_type }) => {
        edt[item.id] = {
          score: parseInt(item.score),
          config_type: item.config_type,
        };
      }
    );
    return edt;
  });

  const updateScore = (id: string, score: number, config_type: config_type) => {
    const tempScores: Record<string, { score: number; config_type: config_type }> = {
      ...editableScoresStage2,
    };
    tempScores[id] = { score: score, config_type };
    if (config_type === 'LOW_RISK') {
      const mediumRiskId = trustIndexAtrr.find(
        (item: { id: string; score: string; config_type: config_type }) =>
          item.config_type === 'MEDIUM_RISK'
      );
      if (mediumRiskId)
        tempScores[mediumRiskId.id] = {
          score: score - 1,
          config_type: 'MEDIUM_RISK',
        };
    }
    setEditableScoresStage2({ ...tempScores });
    dispatch(setTrustedShopperTotal({ ...tempScores }) as any);
  };

  return step > 1 && projectType !== 'SHOPPER_TRUST_INDEX' ? null : (
    <>
      <PublishModal />
      <PublishSuccess />
      {/* <SaveModal {...{ step, setStep, isSaved, setIsSaved }} />
      <SaveSuccess {...{ isSaved, setIsSaved }} /> */}
      <div className="relative flex items-baseline justify-between" id="target18">
        <h5 className=" text-grey-content-secondary text-lg">
          {stepsData[step] && stepsData[step][stage] && stepsData[step][stage]['info']}
        </h5>
        <div className="w-[400px] flex justify-around" id="targetR">
          <div className="flex gap-2">
            <span className="text-sm1 text-grey-content-tertiary leading-0 h-fit text-center">
              Customisation
            </span>
            <button
              onMouseEnter={() => setCustomisationInfoTooltip(true)}
              onMouseLeave={() => setCustomisationInfoTooltip(false)}
              onClick={() => setCustomisationInfoTooltip(!showCustomisationTooltip)}
            >
              <InfoIcon opacity={0.5} />
            </button>
            {showCustomisationTooltip && (
              <ComingSoonToolTip
                className="top-7 right-[200px]"
                isShow={showCustomisationTooltip}
                image={ConsumerTrustIndexImage}
                title="Customised ranges"
                content={[
                  'Fraud teams are empowered to customise Trust Index ranges, leveraging the features built into our statistical models.',
                ]}
              />
            )}
          </div>
          <div className="flex gap-2">
            <span className="text-sm1 text-grey-content-tertiary leading-0 h-fit text-center">
              Recommendation
            </span>
            <button
              onMouseEnter={() => setShowRecommendedTooltip(true)}
              onMouseLeave={() => setShowRecommendedTooltip(false)}
              onClick={() => setShowRecommendedTooltip(!showRecommendedTooltip)}
            >
              <InfoIcon opacity={0.5} />
            </button>
            {showRecommendedTooltip && (
              <ComingSoonToolTip
                className="top-7 right-10"
                isShow={showRecommendedTooltip}
                image={RecommendedWeightingsImage}
                title="Recommended ranges"
                content={[
                  'Our algorithms provide a recommendation of Trust Index ranges for each consumer group.',
                  'Recommendations are updated dynamically based on the data across the network to ensure the highest level of CTI accuracy.',
                ]}
              />
            )}
          </div>
          <DemoGuidePopover
            title="Try it - Segment your consumers"
            body="Change the definition for trusted, medium risk and high risk consumers for your organisation"
            el={() => document.getElementById('target18')}
            topOffset={120}
            leftOffset={420}
            arrowPosition="top"
            maxWidth="max-w-[355px]"
            open={popUp18}
            setOpen={setPopUp18}
            setNext={setPopUp19}
          />
        </div>
      </div>
      <div className="mt-2 mb-14 rounded border border-grey-10 flex flex-col divide-y-[1px] divide-grey-10">
        {trustIndexAtrr?.map((attr: TrustedShopperConfigProps, i: number) => {
          return (
            <div
              className={getClassNames(
                'grid grid-cols-[45px,1fr,200px,200px]  divide-x-[1px divide-grey-10'
              )}
              key={attr.name}
            >
              <div
                className={getClassNames(
                  'text-sm text-grey-content-secondary text-center py-6',
                  editableScoresStage2[attr.id].score === 0 ? 'opacity-50' : ''
                )}
              >
                {i + 1}
              </div>
              <div
                className="relative py-6 px-4 border-l border-l-grey-10 group"
                id={attr.id}
              >
                <span
                  className={getClassNames(
                    'text-lg text-body-black',
                    editableScoresStage2[attr.id].score === 0 ? 'opacity-50' : ''
                  )}
                >
                  {attr.name
                    .replaceAll('shopper', 'consumer')
                    .replaceAll('Shopper', 'Consumer')
                    .replaceAll('shoppers', 'consumers')}
                </span>
                <p
                  className={getClassNames(
                    'mt-2 text-grey-content-tertiary',
                    editableScoresStage2[attr.id].score === 0 ? 'opacity-50' : ''
                  )}
                >
                  {attr.description
                    .replaceAll('shopper', 'consumer')
                    .replaceAll('Shopper', 'Consumer')
                    .replaceAll('shoppers', 'consumers')}
                </p>
              </div>
              <div className="flex flex-col gap-y-1 items-center justify-center py-6">
                <Input
                  name={attr.id.toString() + 'the'}
                  {...{ register }}
                  value={
                    editableScoresStage2[attr.id].score === 0
                      ? ' -'
                      : editableScoresStage2[attr.id].score
                  }
                  disabled={i === 1}
                  rules={{ required: true }}
                  onChange={(v) => {
                    let val = parseInt(v.target.value);
                    if (val < 0 || isNaN(val)) {
                      val = parseInt(v.target.value.replace(/-/g, '')) || 0;
                    }
                    if (val >= 100) {
                      val = 99;
                    }
                    updateScore(attr.id, val, attr.config_type);
                  }}
                  className={getClassNames(
                    'mx-auto max-w-[52px] focus:border-desc-grey',
                    ['text-risk-green', 'text-trud-orange', 'text-risk-red'][i % 3]
                  )}
                />

                <span className={getClassNames('rounded-2xl px-2 text-sm1')}>
                  {i > 0 ? 'or less' : 'or more'}
                </span>
              </div>
              <div className="flex flex-col gap-y-1 items-center justify-center py-6 bg-primary border-l border-l-grey-10">
                <Input
                  disabled
                  name={'aosdhjn' + i}
                  {...{ register }}
                  defaultValue={
                    attr.recommended === '0' ? ' -' : parseInt(attr.recommended)
                  }
                  rules={{ required: true }}
                  className="mx-auto max-w-[52px] bg-stroke text-grey-content-secondary"
                />

                <span className={getClassNames('rounded-2xl px-2  text-sm1')}>
                  {i > 0 ? 'or less' : 'or more'}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TrustedShopperConfigSetup;
